import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, SelectMark } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../data/snippets/select-mark.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, SelectMark: SelectMark }}
        code={snippet}
        platform="react"
        gitHubLink="select-mark"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>Determines the size of the select mark.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="selectedState" types={['oneOf', 'string']}>
            <Text>
              Determines whether the select mark is selected. This should be
              controlled by state through the <code>onClick</code> prop. If a
              user is leaving a multi-select mode, set this to{' '}
              <code>default</code>. If a user is deselecting a single object,
              set this to <code>unselected</code>.
            </Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>selected</code>
              </li>
              <li>
                <code>unselected</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="isHovered" types={['bool']}>
            <Text>
              Determines whether to force the hover state of the select mark.
              Useful when you want to show the select mark's hover state when
              hovering over a large parent element like a video thumbnail.
            </Text>
          </PropListItem>

          <PropListItem name="onClick" types={['func']}>
            <Text>Responds to the select mark click event.</Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this select mark in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-helpers/space">
                uniCSS space classes
              </Link>
              .
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            Since the Select Mark is stateless, the selected state of the Select
            Mark needs to be handled with <code>onClick</code>.
          </li>
          <li>
            For accessibility purposes, <code>onClick</code> will be called when
            the spacebar is hit. Additionally, the
            <code> role</code> is set as <code>checkbox</code> with a supporting{' '}
            <code>aria-checked</code> attribute to help with users using screen
            readers.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
