import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { SelectMark } from 'hudl-rn-uniform-ui';

const snippet = require('raw-loader!../../../../data/snippets/rn-select-mark.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, SelectMark: SelectMark }}
        code={snippet}
        platform="react-native"
        gitHubLink="select-mark"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>Determines the size of the select mark.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="selectedState" types={['oneOf', 'string']}>
            <Text>
              Determines whether the select mark is selected. This should be
              controlled by state through the <code>onPress</code> prop. If a
              user is leaving a multi-select mode, set this to{' '}
              <code>default</code>. If a user is deselecting a single object,
              set this to <code>unselected</code>.
            </Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>selected</code>
              </li>
              <li>
                <code>unselected</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="space" types={['oneOf', 'string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-variables/space?reactnative">
                space variables
              </Link>
              .
            </Text>
          </PropListItem>

          <PropListItem name="theme" types={['oneOf', 'string']}>
            <Text>Determines the theme of the select mark.</Text>
            <List type="unordered">
              <li>
                <code>light</code> (default)
              </li>
              <li>
                <code>dark</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this select mark in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the select mark.
            </Text>
          </PropListItem>

          <PropListItem name="onPress" types={['func']}>
            <Text>Responds to the select mark press event.</Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
